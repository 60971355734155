<template>
  <div class="appLoader">
    <div :class="`myLoader`" :style="`--size:${size || '72px'}`">
      <iconic class="spinner" name="gspinner" />
      <iconic class="logo" name="lock1a" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["size"],
};
</script>

<style lang="scss">
.appLoader {
  @include Fixed();
  z-index: 999;
  .myLoader {
    color: $primary_color;
    width: var(--size);
    height: var(--size);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    @include Centered();
    .spinner {
      font-size: var(--size);
    }
    svg.gspinner circle {
      stroke-width: 6% !important;
    }
    .logo {
      position: absolute;
      font-size: calc(var(--size) * 0.4);
    }
    .iconic {
      @include iconic();
    }
  }
}
</style>
